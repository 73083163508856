import axios from 'axios'
import config from '../config'

export async function login(username, password) {
  logout()
  const response = await axios({
    method: 'post',
    url: `${config.base_api_url}/users/login`,
    data: { username, password, checkAdmin: true },
  })
  const token = await response.data['token']
  setToken(token)
}

export function logout() {
  localStorage.removeItem('token')
}

export function isLoggedIn() {
  return localStorage.getItem('token') ? true : false
}

export function getToken() {
  localStorage.getItem('token')
}

export function setToken(token) {
  localStorage.setItem('token', token)
}