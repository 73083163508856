import React from 'react'
import Chip from '@material-ui/core/Chip';

const status = ['Indefinido',
    'Pedido criado',
    'Pedido aprovado',
    'Pedido separado',
    'Pedido enviado',
    'pedido entregue']

const OrderField = ({ record = {}, source, classes }) => <Chip label={status[record[source]]} variant="outlined" color="primary"/>        
        
export default OrderField