import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List, SingleFieldList, ArrayInput, FormDataConsumer } from 'react-admin'
import { Datagrid, TextField, EditButton, Edit, SimpleForm, DisabledInput, ReferenceManyField, ArrayField, Filter } from 'react-admin'
import { TextInput, Create, ReferenceField, SelectInput, ReferenceInput, ImageInput, ImageField, SimpleFormIterator } from 'react-admin'
import StatusField from './StatusField'

const ProductShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

const ProductFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <TextInput label="Nome" source="name" defaultValue="" />
  </Filter>
)

export const ProductList = props => (
  <List title={<ProductLabel />} {...props} sort={{ field: 'name', order: 'DESC' }} filters={<ProductFilter />}>
    <Datagrid rowClick="show">
      <TextField label="Nome" source="name" />      
      <ReferenceField label="Categoria" source="id_category" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Valor atacado" source="wholesale_price" />
      <TextField label="Valor atacado com desconto" source="wholesale_promotional_price" />
      <TextField label="Valor varejo sugerido" source="mrp" />
      <TextField label="Valor varejo sugerido com desconto" source="selling_price" />
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const ProductLabel = () => {
  return <span>Produtos</span>
}

const ProductTitle = ({ record }) => {
  return <span>Produto {record ? `"${record.description}"` : ''}</span>;
}

export const ProductShow = (props) => (
  <Show title={<ProductTitle />} actions={<ProductShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Fabricante" source="id_manufacturer" reference="manufacturers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Categoria" source="id_category" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Nome" source="name" />
      <TextField label="Descrição" source="description" />      
      <TextField label="Valor atacado" source="wholesale_price" />
      <TextField label="Valor atacado com desconto" source="wholesale_promotional_price" />
      <TextField label="Valor varejo sugerido" source="mrp" />
      <TextField label="Valor varejo sugerido com desconto" source="selling_price" />
      <ArrayField label="Modelos" source="sub_products">
        <Datagrid>
          <TextField label="Descrição" source="description" />
          <TextField label="Quantidade em estoque" source="available" />
        </Datagrid>
      </ArrayField>
      <ReferenceManyField
        label="Imagens"
        reference="photos"
        target="id_product">
        <SingleFieldList>
          <ImageField source="uri" />
        </SingleFieldList>
      </ReferenceManyField>      
    </SimpleShowLayout>
  </Show>
)

export const ProductEdit = props => (
  <Edit title={<ProductTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo produto" source="id_product" />
      <ReferenceInput label="Empresa" source="id_company" reference="companies">
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput label="Fabricante" source="id_manufacturer" reference="manufacturers">
        <SelectInput source="name" />
      </ReferenceInput>    
      <FormDataConsumer>
        {({ formData, ...rest }) => {        
            return (
            <ReferenceInput
              label="Categoria"
              source="id_category"
              reference="categories/companies"
              filter={{ id_company: formData.id_company }}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>)
        }
        }
      </FormDataConsumer>    
      <TextInput label="Nome" source="name" />
      <TextInput label="Descrição" source="description" />
      <TextInput label="Valor atacado" source="wholesale_price" />
      <TextInput label="Valor atacado com desconto" source="wholesale_promotional_price" />
      <TextInput label="Valor varejo sugerido" source="mrp" />
      <TextInput label="Valor varejo sugerido com desconto" source="selling_price" />          
      <ArrayInput label="Modelos" source="sub_products">
        <SimpleFormIterator disableRemove>
          <DisabledInput label="Codigo modelo" source="id_sub_product" />
          <TextInput label="Descrição" source="description" />
          <TextInput label="Quantidade em estoque" source="available" />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput source="pictures" label="Adicionar novas imagens" accept="image/*" multiple={true}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceManyField
        label="Imagens"
        reference="photos"
        target="id_product">
        <SingleFieldList>
          <ImageField source="uri" />
        </SingleFieldList>
      </ReferenceManyField>
      <SelectInput source="status" choices={[
        { id: 1, name: 'Habilitado' },
        { id: 0, name: 'Desabilitado' },
      ]} />
    </SimpleForm>
  </Edit>
)

export const ProductCreate = props => (
  <Create title={<ProductLabel />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Empresa" source="id_company" reference="companies">
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput label="Fabricante" source="id_manufacturer" reference="manufacturers">
        <SelectInput source="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
        
            return (
            <ReferenceInput
              label="Categoria"
              source="id_category"
              reference="categories/companies"
              filter={{ id_company: formData.id_company }}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>)
        }
        }
      </FormDataConsumer>
      <TextInput label="Nome" source="name" />
      <TextInput label="Descrição" source="description" />
      <TextInput label="Valor atacado" source="wholesale_price" />
      <TextInput label="Valor atacado com desconto" source="wholesale_promotional_price" />
      <TextInput label="Valor varejo sugerido" source="mrp" />
      <TextInput label="Valor varejo sugerido com desconto" source="selling_price" />
      <ArrayInput label="Modelos" source="sub_products">
        <SimpleFormIterator>
          <TextInput label="Descrição" source="description" />
          <TextInput label="Quantidade em estoque" source="available" />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput source="pictures" label="Imagens" accept="image/*" multiple={true}>
        <ImageField source="src" title="title" />
      </ImageInput>      
    </SimpleForm>
  </Create>
)