import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List, Datagrid, TextField, EditButton } from 'react-admin'
import { Edit, SimpleForm, DisabledInput, TextInput, Create, ReferenceField, SelectInput, ReferenceInput } from 'react-admin'
import StatusField from './StatusField'

const CategoryShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export const CategoryList = props => (
  <List title={<CategoryLabel />} {...props}>
    <Datagrid rowClick="show">
      <TextField label="Nome" source="name" />
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const CategoryLabel = () => {
  return <span>Categoria</span>
}

const CategoryTitle = ({ record }) => {
  return <span>Categoria {record ? `"${record.name}"` : ''}</span>;
}

export const CategoryShow = (props) => (
  <Show title={<CategoryTitle />} actions={<CategoryShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Nome" source="name" />
    </SimpleShowLayout>
  </Show>
)

export const CategoryEdit = props => (
  <Edit title={<CategoryTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo categoria" source="id_category" />
      <DisabledInput label="Codigo empresa" source="id_company" />      
      <TextInput label="Nome" source="name" />
      <SelectInput source="status" choices={[
        { id: 1, name: 'Habilitado' },
        { id: 0, name: 'Desabilitado' },
      ]} />
    </SimpleForm>
  </Edit>
)

export const CategoryCreate = props => (
  <Create title={<CategoryLabel />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Empresa" source="id_company" reference="companies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Nome" source="name" />
    </SimpleForm>
  </Create>
)