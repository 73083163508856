import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List, Datagrid, TextField, EditButton } from 'react-admin'
import { Edit, SimpleForm, DisabledInput, TextInput, Create, ReferenceField, SelectInput, ReferenceInput } from 'react-admin'
import StatusField from './StatusField'

const CategoryRetailPriceShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export const CategoryRetailPriceList = props => (
  <List title={<CategoryRetailPriceLabel />} {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="Categoria" source="id_category" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}> 
        <TextField source="personal.name" />
      </ReferenceField>
      <TextField label="Preço varejo" source="mrp" />
      <TextField label="Preço varejo com desconto" source="selling_price" />
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const CategoryRetailPriceLabel = () => {
  return <span>Preço do varejo por categoria</span>
}

const CategoryRetailPriceTitle = ({ record }) => {
  return <span>Preço do varejo por categoria</span>
}

export const CategoryRetailPriceShow = (props) => (
  <Show title={<CategoryRetailPriceTitle />} actions={<CategoryRetailPriceShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Categoria" source="id_category" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <TextField source="personal.name" />
      </ReferenceField>
      <TextField label="Preço varejo" source="mrp" />
      <TextField label="Preço varejo com desconto" source="selling_price" />
    </SimpleShowLayout>
  </Show>
)

export const CategoryRetailPriceEdit = props => (
  <Edit title={<CategoryRetailPriceTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo preços por categoria" source="id_category_retail_price" />
      <DisabledInput label="Codigo categoria" source="id_category" />
      <DisabledInput label="Codigo consultor(a)" source="id_consultant" />
      <TextInput label="Preço varejo" source="mrp" />
      <TextInput label="Preço varejo com desconto" source="selling_price" />
    </SimpleForm>
  </Edit>
)

export const CategoryRetailPriceCreate = props => (
  <Create title={<CategoryRetailPriceLabel />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Categoria" source="id_category" reference="categories" perPage={1000} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <SelectInput optionText="personal.name" />
      </ReferenceInput>
      <TextInput label="Preço varejo" source="mrp" />
      <TextInput label="Preço varejo com desconto" source="selling_price" />
    </SimpleForm>
  </Create>
)