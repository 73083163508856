import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

export default function OrderItemsList({ record = {}, source, classes }) {
  return (    
    <Paper style={{'marginTop':'20px'}}>
        {JSON.stringify(record[source])}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="right">Nome</TableCell>
            <TableCell align="right">Descrição</TableCell>
            <TableCell align="right">Tamanho</TableCell>
            <TableCell align="right">Quantidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.items.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                <img src={row.id_photo} alt={row.name} style={{'width':'150px', 'height':'150px'}}/>
              </TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{row.model}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}