import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List, Datagrid, TextField, EditButton } from 'react-admin'
import { Edit, SimpleForm, DisabledInput, TextInput, Create, ReferenceField, SelectInput, ReferenceInput } from 'react-admin'
import StatusField from './StatusField'

const RetailPriceShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export const RetailPriceList = props => (
  <List title={<RetailPriceLabel />} {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="Produto" source="id_product" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <TextField source="personal.name" />
      </ReferenceField>
      <TextField label="Preço varejo" source="mrp" />
      <TextField label="Preço varejo com desconto" source="selling_price" />
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const RetailPriceLabel = () => {
  return <span>Preço do varejo por produto</span>
}

const RetailPriceTitle = ({ record }) => {
  return <span>Produto {record ? `"${record.name}"` : ''}</span>
}

export const RetailPriceShow = (props) => (
  <Show title={<RetailPriceTitle />} actions={<RetailPriceShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Produto" source="id_product" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <TextField source="personal.name" />
      </ReferenceField>
      <TextField label="Preço varejo" source="mrp" />
      <TextField label="Preço varejo com desconto" source="selling_price" />
    </SimpleShowLayout>
  </Show>
)

export const RetailPriceEdit = props => (
  <Edit title={<RetailPriceTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo" source="id_retail_price" />
      <DisabledInput label="Codigo produto" source="id_product" />
      <DisabledInput label="Codigo consultor(a)" source="id_consultant" />
      <TextInput label="Preço varejo" source="mrp" />
      <TextInput label="Preço varejo com desconto" source="selling_price" />
    </SimpleForm>
  </Edit>
)

export const RetailPriceCreate = props => (
  <Create title={<RetailPriceLabel />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Produto" source="id_product" reference="products">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <SelectInput optionText="personal.name" />
      </ReferenceInput>
      <TextInput label="Preço varejo" source="mrp" />
      <TextInput label="Preço varejo com desconto" source="selling_price" />
    </SimpleForm>
  </Create>
)