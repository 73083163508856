import React from 'react'
import { FormDataConsumer, CardActions, ListButton, Show, SimpleShowLayout, List, Datagrid, TextField, EditButton, Edit, SimpleForm } from 'react-admin'
import { DisabledInput, TextInput, Create, ReferenceField, SelectInput, ReferenceInput, Filter } from 'react-admin'
import StatusField from './StatusField'

const ClientShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

const ClientFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <TextInput label="Nome" source="personal.name" defaultValue="" />
  </Filter>
)

export const ClientList = props => (
  <List title={<ClientLabel />} {...props} sort={{ field: 'personal.name', order: 'DESC' }} filters={<ClientFilter />}>
    <Datagrid rowClick="show">
      <TextField label="Nome" source="personal.name" />
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <TextField source="personal.name" />
      </ReferenceField>
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const ClientLabel = () => {
  return <span>Cliente</span>
}

const ClientTitle = ({ record }) => {
  return <span>Cliente {record ? `"${record.personal.name}"` : ''}</span>;
}

export const ClientShow = (props) => (
  <Show title={<ClientTitle />} actions={<ClientShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <TextField source="personal.name" />
      </ReferenceField>
      <TextField label="Nome" source="personal.name" />
      <TextField label="Email" source="personal.email" type="email" />
      <TextField label="Telefone(1)" source="personal.phone1" />
      <TextField label="Telefone(2)" source="personal.phone2" />
      <TextField label="Endereço" source="address.main" />
      <TextField label="Complemento" source="address.extra" />
      <TextField label="Cep" source="address.zipcode" />
      <TextField label="Cidade" source="address.city.name" />
      <TextField label="Estado" source="address.city.state.name" />
    </SimpleShowLayout>
  </Show>
)

export const ClientEdit = props => (
  <Edit title={<ClientTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo funcionário" source="id_client" />
      <DisabledInput label="Codigo empresa" source="id_company" />
      <DisabledInput label="Codigo usuário" source="id_user" />
      <DisabledInput label="Codigo pessoal" source="id_personal" />
      <ReferenceInput label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}>
        <SelectInput optionText="personal.name" />
      </ReferenceInput>
      <TextInput label="Nome" source="personal.name" />
      <TextInput label="Email" source="personal.email" type="email" />
      <TextInput label="Telefone(1)" source="personal.phone1" />
      <TextInput label="Telefone(2)" source="personal.phone2" />

      <TextInput label="Endereço" source="address.main" />
      <TextInput label="Complemento" source="address.extra" />
      <TextInput label="CEP" source="address.zipcode" />
      <ReferenceInput label="Estado" source="address.city.id_state" reference="states">
        <SelectInput optionText="initials" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <ReferenceInput
              label="Cidade"
              source="address.id_city"
              reference="cities/state"
              filter={{ id_state: formData.address.city.id_state }}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>)
        }
        }
      </FormDataConsumer>

      <TextInput label="Usuário" source="user_data.username" />
      <TextInput label="Nova senha" source="user_data.password" type="password" />
      <SelectInput source="status" choices={[
        { id: 1, name: 'Habilitado' },
        { id: 0, name: 'Desabilitado' },
      ]} />
    </SimpleForm>
  </Edit>
)

export const ClientCreate = props => (
  <Create title={<ClientLabel />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Empresa" source="id_company" reference="companies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Consultor(a)" source="id_consultant" reference="consultants" perPage={1000} sort={{ field: 'personal.name', order: 'ASC' }}> 
        <SelectInput optionText="personal.name" />
      </ReferenceInput>
      <TextInput label="Nome" source="personal.name" />

      <TextInput label="Endereço" source="address.main" />
      <TextInput label="Complemento" source="address.extra" />
      <TextInput label="CEP" source="address.zipcode" />
      <ReferenceInput label="Estado" source="address.city.id_state" reference="states">
        <SelectInput optionText="initials" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {

          if (!formData.address)
            formData['address'] = { city: { id_state: null } }

          return (
            <ReferenceInput
              label="Cidade"
              source="address.id_city"
              reference="cities/state"
              filter={{ id_state: formData.address.city.id_state }}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>)
        }
        }
      </FormDataConsumer>

      <TextInput label="Email" source="personal.email" type="email" />
      <TextInput label="Telefone(1)" source="personal.phone1" />
      <TextInput label="Telefone(2)" source="personal.phone2" />

      <TextInput label="Usuário" source="user_data.username" />
      <TextInput label="Nova senha" source="user_data.password" type="password" />
    </SimpleForm>
  </Create>
)