import React from 'react'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, DisabledInput, TextInput } from 'react-admin'
import { Create, FormDataConsumer, SelectInput, ReferenceInput, CardActions, ListButton, Show, SimpleShowLayout } from 'react-admin'
import StatusField from './StatusField'

export const CompanyList = props => (
  <List title={<CompanyLabel />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const CompanyLabel = () => {
  return <span>Empresas</span>
}

const CompanyTitle = ({ record }) => {
  return <span>Empresa {record ? `"${record.name}"` : ''}</span>;
}

const CompanyShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export const CompanyShow = (props) => (
  <Show title={<CompanyTitle />} actions={<CompanyShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField label="Nome" source="name" />
      <TextField label="Endereço" source="address.main" />
      <TextField label="Complemento" source="address.extra" />
      <TextField label="Cep" source="address.zipcode" />
      <TextField label="Cidade" source="address.city.name" />
      <TextField label="Estado" source="address.city.state.name" />
    </SimpleShowLayout>
  </Show>
)

export const CompanyEdit = props => (
  <Edit title={<CompanyTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo empresa" source="id_company" />
      <DisabledInput label="Codigo endereço" source="address.id_address" />
      <TextInput label="Nome" source="name" />
      <TextInput label="Endereço" source="address.main" />
      <TextInput label="Complemento" source="address.extra" />
      <TextInput label="CEP" source="address.zipcode" />
      <ReferenceInput label="Estado" source="address.city.id_state" reference="states">
        <SelectInput optionText="initials" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <ReferenceInput
              label="Cidade"
              source="address.id_city"
              reference="cities/state"
              filter={{ id_state: formData.address.city.id_state }}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>)
        }
        }
      </FormDataConsumer>
      <SelectInput source="status" choices={[
        { id: 1, name: 'Habilitado' },
        { id: 0, name: 'Desabilitado' },
      ]} />
    </SimpleForm>
  </Edit>
)

export const CompanyCreate = props => (
  <Create title={<CompanyLabel />} {...props}>
    <SimpleForm>
      <TextInput label="Nome" source="name" />
      <TextInput label="Endereço" source="address.main" />
      <TextInput label="Complemento" source="address.extra" />
      <TextInput label="CEP" source="address.zipcode" />
      <ReferenceInput label="Estado" source="address.city.id_state" reference="states">
        <SelectInput optionText="initials" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if(!formData.address)
            formData['address'] = {city:{id_state:null}}
          return (
            <ReferenceInput
              label="Cidade"
              source="address.id_city"
              reference="cities/state"
              filter={{ id_state: formData.address.city.id_state }}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>)
        }
      }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)
