import React from 'react'
import { TextField, Edit, SimpleForm, DisabledInput, Toolbar, DeleteButton, ReferenceField, ImageField } from 'react-admin'

const PhotoTitle = ({ record }) => {
  return <span>Imagem </span>;
}

const redirect = (props) => {
  return `/products/${props.record.id_product}/show`
}

const PhotoEditToolbar = props => (
  <Toolbar {...props} >
      <DeleteButton
        redirect={redirect(props)}
      />
  </Toolbar>
)

export const PhotoEdit = props => (
  <Edit title={<PhotoTitle />} {...props}>
    <SimpleForm toolbar={<PhotoEditToolbar/>}>
      <DisabledInput label="Codigo imagem" source="id_photo" />
      <ReferenceField label="Produto" source="id_product" reference="products">
        <TextField source="description" />
      </ReferenceField>
      <ImageField label="Imagem" source="uri" />
    </SimpleForm>
  </Edit>
)