import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List, Datagrid, TextField, EditButton, Edit, SimpleForm, DisabledInput, TextInput, Create, ReferenceField, SelectInput, ReferenceInput } from 'react-admin'
import StatusField from './StatusField'

const EmployeeShowActions = ({ basePath, data, resource }) => (
  <CardActions>
      <ListButton basePath={basePath} record={data} />    
      <EditButton basePath={basePath} record={data} />
  </CardActions>
)

export const EmployeeList = props => (
  <List title={<EmployeeLabel />} {...props}>
    <Datagrid  rowClick="show">
      <TextField label="Nome" source="personal.name" />
      <ReferenceField label="Empresa" source="id_company" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <StatusField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const EmployeeLabel = () => {
  return <span>Funcionário</span>
}

const EmployeeTitle = ({ record }) => {
  return <span>Funcionário {record ? `"${record.personal.name}"` : ''}</span>;
}

export const EmployeeShow = (props) => (
  <Show title={<EmployeeTitle />} actions={<EmployeeShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Nome" source="personal.name" />
        <TextField label="Email" source="personal.email" type="email" />
        <TextField label="Telefone(1)" source="personal.phone1" />
        <TextField label="Telefone(2)" source="personal.phone2" />
        <TextField label="Usuário" source="user_data.username" />
      </SimpleShowLayout>
  </Show>
)

export const EmployeeEdit = props => (
  <Edit title={<EmployeeTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo funcionário" source="id_employee" />
      <DisabledInput label="Codigo empresa" source="id_company" />
      <DisabledInput label="Codigo usuário" source="id_user" />
      <DisabledInput label="Codigo pessoal" source="id_personal" />
      <TextInput label="Nome" source="personal.name" />
      <TextInput label="Email" source="personal.email" type="email" />
      <TextInput label="Telefone(1)" source="personal.phone1" />
      <TextInput label="Telefone(2)" source="personal.phone2" />
      <TextInput label="Usuário" source="user_data.username" />
      <TextInput label="Nova senha" source="user_data.password" type="password" />
      <SelectInput source="status" choices={[
        { id: 1, name: 'Habilitado' },
        { id: 0, name: 'Desabilitado' },
      ]} />
    </SimpleForm>
  </Edit>
)

export const EmployeeCreate = props => (
  <Create title={<EmployeeLabel />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Empresa" source="id_company" reference="companies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Nome" source="personal.name" />
      <TextInput label="Email" source="personal.email" type="email" />
      <TextInput label="Telefone(1)" source="personal.phone1" />
      <TextInput label="Telefone(2)" source="personal.phone2" />
      <TextInput label="Usuário" source="user_data.username" />
      <TextInput label="Nova senha" source="user_data.password" type="password" />      
    </SimpleForm>
  </Create>
)