import React from 'react'
import config from './config'
import { fetchUtils, Admin, Resource } from 'react-admin'
import { CompanyList, CompanyEdit, CompanyCreate, CompanyShow } from './companies'
import { ManufacturerList, ManufacturerEdit, ManufacturerCreate, ManufacturerShow } from './manufacturers'
import { EmployeeList, EmployeeEdit, EmployeeCreate, EmployeeShow } from './employees'
import { ConsultantList, ConsultantEdit, ConsultantCreate, ConsultantShow } from './consultants'
import { ClientList, ClientEdit, ClientCreate, ClientShow } from './clients'
import { ProductList, ProductEdit, ProductCreate, ProductShow } from './products'
import { CategoryList, CategoryEdit, CategoryCreate, CategoryShow } from './categories'
import { CategoryRetailPriceList, CategoryRetailPriceEdit, CategoryRetailPriceCreate, CategoryRetailPriceShow } from './categoryRetailPrices'
import { RetailPriceList, RetailPriceEdit, RetailPriceCreate, RetailPriceShow } from './retailPrices'
import { BannerList, BannerCreate, BannerShow } from './banners'
import { PhotoEdit } from './photos'
import { OrdersList, OrdersEdit, OrdersShow } from './orders'

import jsonServerProvider from 'ra-data-json-server'
import CompanyIcon from '@material-ui/icons/StoreMallDirectory'
import ManufacturerIcon from '@material-ui/icons/LocationCity'
import EmployeeIcon from '@material-ui/icons/AssignmentInd'
import ConsultantIcon from '@material-ui/icons/BusinessCenter'
import ClientIcon from '@material-ui/icons/Face'
import ProductIcon from '@material-ui/icons/CardGiftcard'
import CategoryIcon from '@material-ui/icons/ViewModule'
import CategoryRetailPriceIcon from '@material-ui/icons/MonetizationOn'
import BannerIcon from '@material-ui/icons/Pages'
import OrdersIcon from '@material-ui/icons/ShoppingCart'
import Dashboard from './Dashboard'
import authProvider from './authProvider'
import addUploadFeature from './addUploadFeature'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
  }
  options.headers.set('x-access-token', localStorage.getItem('token'))
  options.headers.set('Access-Control-Allow-Origin', '*')
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = jsonServerProvider(config.base_api_url, httpClient)
const uploadCapableDataProvider = addUploadFeature(dataProvider)

const App = () => (
  <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={uploadCapableDataProvider}>    
    <Resource name="companies"              options={{ label: 'Empresas' }}          list={CompanyList}             edit={CompanyEdit}              create={CompanyCreate}              show={CompanyShow}              icon={CompanyIcon}/>
    <Resource name="manufacturers"          options={{ label: 'Fabricantes' }}       list={ManufacturerList}        edit={ManufacturerEdit}         create={ManufacturerCreate}         show={ManufacturerShow}         icon={ManufacturerIcon}/>
    <Resource name="employees"              options={{ label: 'Funcionários' }}      list={EmployeeList}            edit={EmployeeEdit}             create={EmployeeCreate}             show={EmployeeShow}             icon={EmployeeIcon}/>
    <Resource name="consultants"            options={{ label: 'Consultores' }}       list={ConsultantList}          edit={ConsultantEdit}           create={ConsultantCreate}           show={ConsultantShow}           icon={ConsultantIcon}/>
    <Resource name="clients"                options={{ label: 'Clientes' }}          list={ClientList}              edit={ClientEdit}               create={ClientCreate}               show={ClientShow}               icon={ClientIcon}/>
    <Resource name="categories"             options={{ label: 'Categorias' }}        list={CategoryList}            edit={CategoryEdit}             create={CategoryCreate}             show={CategoryShow}             icon={CategoryIcon}/>
    <Resource name="products"               options={{ label: 'Produtos' }}          list={ProductList}             edit={ProductEdit}              create={ProductCreate}              show={ProductShow}              icon={ProductIcon}/>
    <Resource name="categoryretailprices"   options={{ label: 'Preços Categorias' }} list={CategoryRetailPriceList} edit={CategoryRetailPriceEdit}  create={CategoryRetailPriceCreate}  show={CategoryRetailPriceShow}  icon={CategoryRetailPriceIcon}/>
    <Resource name="retailprices"           options={{ label: 'Preços Varejo' }}     list={RetailPriceList}         edit={RetailPriceEdit}          create={RetailPriceCreate}          show={RetailPriceShow}          icon={CategoryRetailPriceIcon}/>
    <Resource name="banners"                options={{ label: 'Banners' }}           list={BannerList}                                              create={BannerCreate}               show={BannerShow}               icon={BannerIcon}/>
    <Resource name="orders"                 options={{ label: 'Pedidos' }}           list={OrdersList}              edit={OrdersEdit}                                                   show={OrdersShow}               icon={OrdersIcon}/>
    <Resource name="states" />
    <Resource name="cities/state" />
    <Resource name="categories/companies" />
    <Resource name="photos" edit={PhotoEdit} />
  </Admin>
)

export default App
