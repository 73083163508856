import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import { login, logout, isLoggedIn } from './services/auth'

export default async (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params
        await login(username, password)
        return Promise.resolve()
    }

    if (type === AUTH_LOGOUT) {
        logout()
        return Promise.resolve()
    }
    
    if (type === AUTH_ERROR) {
        const { status } = params
        if (status === 401 || status === 403) {
            logout()
            return Promise.reject()
        }
        return Promise.resolve()
    }

    if (type === AUTH_CHECK) {
        return isLoggedIn()
            ? Promise.resolve()
            : Promise.reject()
    }
    return Promise.reject('Unknown method')
}