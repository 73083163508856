import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List } from 'react-admin'
import { Datagrid, DeleteButton, SimpleForm } from 'react-admin'
import { Create, ImageInput, ImageField } from 'react-admin'
import StatusField from './StatusField'

const BannerShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <DeleteButton basePath={basePath} record={data} />
  </CardActions>
)

export const BannerList = props => (
  <List title={<BannerLabel />} {...props}>
    <Datagrid rowClick="show">
      <ImageField label="Imagem" source="uri" />
      <StatusField source="status" />
    </Datagrid>
  </List>
)

const BannerLabel = () => {
  return <span>Banner</span>
}

const BannerTitle = ({ record }) => {
  return <span>Banner</span>
}

export const BannerShow = (props) => (
  <Show title={<BannerTitle />} actions={<BannerShowActions />} {...props}>
    <SimpleShowLayout>      
      <ImageField source="uri" />
    </SimpleShowLayout>
  </Show>
)

export const BannerCreate = props => (
  <Create title={<BannerLabel />} {...props}>
    <SimpleForm>      
      <ImageInput source="picture" label="Banner" accept="image/*" multiple={false}>
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
)