import React from 'react'
import { CardActions, ListButton, Show, SimpleShowLayout, List, Datagrid, TextField, EditButton } from 'react-admin'
import { Edit, SimpleForm, DisabledInput, SelectInput, ReferenceField, Filter, TextInput } from 'react-admin'
import OrderField from './OrderField'
import DateField from './DateField'
import OrderItemsList from './OrderItemsList'

const OrdersShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </CardActions>
)

const OrderFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <TextInput label="Cliente" source="client" defaultValue="" />
      <TextInput label="Consultor(a)" source="consultant" defaultValue="" />
  </Filter>
)

export const OrdersList = props => (
  <List title={<OrdersLabel />} {...props} filters={<OrderFilter />}>
    <Datagrid rowClick="show">
      <TextField label="Número do pedido" source="id_order" />
      <ReferenceField label="Cliente" source="id_client" reference="clients">
        <TextField source="personal.name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants">
        <TextField source="personal.name" />
      </ReferenceField>
      <DateField label="Data" source="date" />
      <OrderField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

const OrdersLabel = () => {
  return <span>Pedidos</span>
}

const OrdersTitle = ({ record }) => {
  return <span>Pedidos</span>
}

export const OrdersShow = (props) => (
  <Show title={<OrdersTitle />} actions={<OrdersShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Cliente" source="id_client" reference="clients">
        <TextField source="personal.name" />
      </ReferenceField>
      <ReferenceField label="Consultor(a)" source="id_consultant" reference="consultants">
        <TextField source="personal.name" />
      </ReferenceField>
      <TextField label="Estado do pedido:" />
      <OrderField label="Estado do pedido" source="status" />
      <OrderItemsList />      
    </SimpleShowLayout>
  </Show>
)

export const OrdersEdit = props => (
  <Edit title={<OrdersTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Codigo Cliente" source="id_client" />
      <DisabledInput label="Codigo consultor(a)" source="id_consultant" />
      <SelectInput label="Status do pedido" source="status" choices={[
        { id: 0, name: 'Indefinido' },
        { id: 1, name: 'Pedido criado' },
        { id: 2, name: 'Pedido aprovado' },
        { id: 3, name: 'Pedido separado' },
        { id: 4, name: 'Pedido enviado' },
        { id: 5, name: 'Pedido entregue' },
      ]} />
    </SimpleForm>
  </Edit>
)